import React from "react";
import dynamic from "next/dynamic";
import PageWrapper from "../../../wrappers/page-wrapper";

const LoginDynamicNoSSR = dynamic(
    () => import("@tw/customer-identity-cra/lib/login/login"),
    { ssr: false }
);

function Login() {
    return <LoginDynamicNoSSR />;
}

Login.reduxModules = LoginDynamicNoSSR.reduxModules;
Login.getInitialProps = async () => {
    return { login: true };
};
Login.domainName = "customer-identity";

export default PageWrapper(Login);
